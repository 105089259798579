<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
                <em>当前位置：</em>
                <a href="javascript:;">题库管理</a>
                <i>></i>
                <a href="javascript:;" @click="goBack">模拟考试配置</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">{{ mockId ? '编辑模拟考试' : '新增模拟考试' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box" style="width:100%">
              <el-form ref="ruleForm" label-width="9rem" style="width: 60%;" class="form" :model="ruleForm" :rules="rules">
                <h4>模拟考试信息</h4>
                <el-form-item label="模拟考试名称：" prop="mockName">
                  <el-input size="small" v-model="ruleForm.mockName" placeholder="请输入模拟考试名称" maxlength="30" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="考试时长：" prop="duration">
                  <el-input-number v-model="ruleForm.duration" :min="1" :step="1" :precision="0" placeholder="请输入考试时长" :controls="false" size="small"></el-input-number>&nbsp;&nbsp;分钟
                </el-form-item>
                <el-form-item label="多选题计分方式：" prop="scoringRule">
                  <el-radio-group v-model="ruleForm.scoringRule" orientation="horizontal" @change="scoringRuleChange">
                    <el-radio :label="item.value" v-for="(item,index) in scoringRuleList" :key="index">
                      {{ item.label }}
                      <template v-if="item.value == '20'">
                        <el-input-number v-model="ruleForm.scoringRuleRatio" :min="0" :max="100" :step="1" :precision="0" placeholder="请输入百分比" :controls="false" size="small" @blur="scoringRuleRatioBlur"></el-input-number>&nbsp;&nbsp;%
                      </template>
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="题库：" prop="bankId">
                  <el-select
                      v-model="ruleForm.bankId"
                      placeholder="请选择题库"
                      size="small"
                      clearable
                      filterable
                      :disabled="listData.length"
                  >
                    <el-option
                        v-for="item in QuestionBankList"
                        :key="item.bankId"
                        :label="item.bankName"
                        :value="item.bankId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="注意事项：" prop="matters">
                  <el-input
                      type="textarea"
                      resize="none"
                      :rows="5"
                      v-model="ruleForm.matters"
                      placeholder="请输入注意事项"
                      maxlength="200"
                      show-word-limit
                  ></el-input>
                </el-form-item>

              </el-form>
              <h4>试卷列表</h4>
              <el-button type="primary" class="bgc-bv" size="small" style="margin: 16px 0;" @click="selectTestPaper()">选择试卷</el-button>

              <el-table
                  ref="multipleTable"
                  :data="listData"
                  :height="tableHeight"
                  size="small"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{background:'rgb(92, 107, 232)',color: '#fff'}"
                  stripe
                  v-if="listData.length"
              >
                <el-table-column
                    label="序号"
                    type="index"
                    align="center"
                    :index="indexMethod"
                    width="50"
                />
                <el-table-column
                    label="试卷名称"
                    min-width="150"
                    prop="paperName"
                    show-overflow-tooltip
                />
                <el-table-column
                    label="试题数量"
                    min-width="150"
                    prop="questionNum"
                    show-overflow-tooltip
                />
                <el-table-column
                    label="试题总分"
                    min-width="150"
                    prop="score"
                    show-overflow-tooltip
                />
                <el-table-column
                    label="添加时间"
                    min-width="150"
                    prop="createTimeStr"
                    show-overflow-tooltip
                />

                <el-table-column
                    label="操作"
                    align="center"
                    min-width="180"
                    fixed="right"
                >
                  <template v-slot="{row}">

                    <el-button type="text" style="padding: 0 5px;" @click="delRow(row.paperId)">移除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="btn-box flexcc" style="width:90%;padding-bottom: 10px;margin-top: 20px;">
              <el-button @click="$router.back()" class="bgc-bv">取 消</el-button>
<!--              <el-button v-if="stu === 'add'" class="bgc-bv" @click="doAddSave">确 定</el-button>-->
              <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SelectTestPaper
        ref="SelectTestPaper"
        @eventBus="testPaperBack"
    ></SelectTestPaper>
  </div>
</template>
<script>

import SelectTestPaper from "@/views/inkQuestionBank/SimulatedExamConfig/popup/SelectTestPaper";
export default {
  name: "updateTask",
  components: {
    SelectTestPaper,
  },
  data() {
    return {

      mockId: "",

      data: [],
      // 基本信息
      ruleForm: {
        mockName: "",// 模拟考试名称
        duration: '120',//考试时长
        scoringRule:"10",//多选题计分规则
        scoringRuleRatio: undefined,//部分得分百分比
        bankId:"",//题库
        matters:"1.单选题只有一项最最符合题意的正确答案，多选、错选或不选均不得分\n2.多选题有两个或两个以上符合题意的正确答案，多选、错选、少选均不得分",//注意事项
      },

      rules: {
        mockName:[{required: true, message: '请输入模拟考试名称', trigger: 'blur'}],
        duration:[{required: true, message: '请输入考试时长', trigger: 'blur'}],
        scoringRule:[{required: true, message: '请选择多选题计分方式', trigger: 'change'}],
        bankId:[{required: true, message: '请选择题库', trigger: 'change'}],
        // matters:[{required: true, message: '请输入注意事项', trigger: 'blur'}],
      },
      //题库下拉
      QuestionBankList: [],
      // 多选题计分方式下拉
      scoringRuleList:[],
      // 列表数据
      listData: [],

    };
  },
  created() {
    this.getQuestionBank()
    this.getScoringRule()
    console.log('query2',this.$route.query)
    this.querypPageNum = this.$route.query.pageNum
    this.queryPageSize = this.$route.query.pageSize
    this.queryMockName = this.$route.query.mockName
    this.queryBankId = this.$route.query.bankId

    if (this.$route.query.mockId) {
      this.mockId = this.$route.query.mockId || "";
      this.getInfo()
      // this.getroleById(this.$route.query.id);
      // this.title = "编辑角色类型";
      // this.showTree = true;
      // this.doEdit(this.$route.query.id, this.$route.query.pid);
    }
  },
  // computed: {},
  methods: {
    // 多选题计分方式change
    scoringRuleChange(val){
      if(val == '10'){
        this.ruleForm.matters = "1.单选题只有一项最最符合题意的正确答案，多选、错选或不选均不得分\n2.多选题有两个或两个以上符合题意的正确答案，多选、错选、少选均不得分"
      }else if(val == '20'&&this.ruleForm.scoringRuleRatio){
        this.ruleForm.matters = "1.单选题只有一项最最符合题意的正确答案，多选、错选或不选均不得分\n2.多选题有两个或两个以上符合题意的正确答案，多选、错选均不得分，少选按"+this.ruleForm.scoringRuleRatio+"％比例得分"
      }
    },
    // 多选题计分方式Blur
    scoringRuleRatioBlur(){
      if(this.ruleForm.scoringRule == '20'&&this.ruleForm.scoringRuleRatio){
        this.ruleForm.matters = "1.单选题只有一项最最符合题意的正确答案，多选、错选或不选均不得分\n2.多选题有两个或两个以上符合题意的正确答案，多选、错选均不得分，少选按"+this.ruleForm.scoringRuleRatio+"％比例得分"
      }
    },
    // 获取详情
    getInfo(){
      this.$post("/biz/exam/bank/mock/getInfo",{
        mockId: this.mockId
      },3000,true,6).then(res=>{
        this.ruleForm.mockName = res.data.mockName;
        this.ruleForm.duration = res.data.duration;
        this.ruleForm.scoringRule = res.data.scoringRule;
        this.ruleForm.bankId = res.data.bankId;
        this.ruleForm.matters = res.data.matters||'';
        this.listData = res.data.paperList||[];
        console.log(res.data.paperList)

        if(res.data.scoringRule == '20'){
          this.ruleForm.scoringRuleRatio = res.data.scoringRuleRatio;
        }
      });
    },
    //获取多选题计分规则--码值
    getScoringRule() {
      const ScoringRule = this.$setDictionary("SCORING_RULE", "list");
      for (const key in ScoringRule) {
        this.scoringRuleList.push({
          value: key,
          label: ScoringRule[key],
        });
      }
    },
    // 获取题库列表
    getQuestionBank() {
      this.$post("/biz/exam/bank/pageList",{
        pageNum:1,
        pageSize:1000000
      },3000,true,6).then(res=>{
        // console.log(res?.data?.list)
        this.QuestionBankList = res?.data?.list||[];
      });

    },
    // 选择试卷
    selectTestPaper() {
      if(!this.ruleForm.bankId){
        this.$message.warning("请先选择题库");
        return;
      }else{
        // console.log(this.ruleForm.bankId)
        this.$refs.SelectTestPaper.showPopUp(this.ruleForm.bankId,this.listData);
      }
    },
    // 批量选课件
    testPaperBack(backArr) {
      this.listData = backArr
    },
    // 移除试卷
    delRow(id){
      this.listData.forEach((e,i)=>{

        if(e.paperId == id){
          this.listData.splice(i,1)
        }
      })
    },

    doAddSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(!this.listData.length){
            this.$message.error('请至少添加一张试卷！')
            return
          }
          if(this.ruleForm.scoringRule == '20'&&!this.ruleForm.scoringRuleRatio){
            this.$message.error('请补全答对给分百分比！')
            return
          }

          let params = {
            mockName: this.ruleForm.mockName,
            duration: this.ruleForm.duration,
            scoringRule: this.ruleForm.scoringRule,
            bankId: this.ruleForm.bankId,
            matters: this.ruleForm.matters||''
          };

          let paperList = []
          this.listData.forEach((e,i)=>{
            let item = {
              bankId: e.bankId,
              paperId: e.paperId
            }
            paperList.push(item)
          })
          params.paperList = paperList


          if( this.ruleForm.scoringRule == '20'){
            params.scoringRuleRatio = this.ruleForm.scoringRuleRatio
          }
          let url= "/biz/exam/bank/mock/insert"
          if(this.mockId){
            params.mockId = this.mockId
            url = "/biz/exam/bank/mock/update"
          }
          this.$post(url, params,3000,true,6)
              .then(res => {
                if (res.status == "0") {
                  this.$message({
                    type: "success",
                    message: this.mockId?"编辑成功！":"新增成功!"
                  });
                  this.goBack();
                }
              })
              .catch(() => {
                return;
              });
        }
      })

    },
    // 返回
    goBack(){
      this.querypPageNum = this.$route.query.pageNum
      this.queryPageSize = this.$route.query.pageSize
      this.queryMockName = this.$route.query.mockName
      this.queryBankId = this.$route.query.bankId
      this.$router.push({
        path:"/inkQuestionBank/SimulatedExamConfig/SimulatedExamConfig",
        query:{
          refresh: true,
          querypPageNum: this.querypPageNum,
          queryPageSize: this.queryPageSize,
          queryMockName: this.queryMockName,
          queryBankId: this.queryBankId
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
/deep/.el-radio-group{
  display: inline-block;
}
/deep/.el-radio-group .el-radio{
  margin: 0 20px 0 0;
}
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.roletree {
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #5c6be8 !important;
    border-color: #5c6be8 !important;
  }
  .custom-tree-node {
    width: 100%;
  }
  .el-tree-node__content {
    height: 100%;
    border-bottom: 1px solid #ccc;
    .el-checkbox {
      margin-left: 10px;
    }
  }
  .el-tree {
    border: 1px solid #ccc;
    border-bottom: 0;

    > div {
      > .el-tree-node__content {
        background-color: #eee;
        height: 30px;
        padding-left: 10px;
      }
      > .el-tree-node__children {
        .el-tree-node {
          background-color: #fff;
          .el-tree-node__content {
            padding: 10px 0 10px 18px;
            .level2-parent {
              padding-right: 20px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .el-tree-node__expand-icon {
    display: none;
  }
}
</style>

