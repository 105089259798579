<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="90%"
    title="选择试卷"
    :center="true"
    :before-close="doClose"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
          <div title="试卷名称" class="searchboxItem">
            <span class="itemLabel">试卷名称:</span>
            <el-input
              clearable
              v-model="questionBankName"
              type="text"
              size="small"
              placeholder="请输入试卷名称"
            />
          </div>
          <span title="创建时间" class="searchboxItem ci-full flexcc">
            <span class="itemLabel">创建时间:</span>
            <el-date-picker
                  clearable
                  size="small"
                  v-model="createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
          </span>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            :header-cell-style="tableHeader"
            tooltip-effect="dark"
            row-key="paperId"
            style="width: 100%"
            @selection-change="change"
            stripe
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="试卷名称"
              align="center"
              show-overflow-tooltip
              prop="paperName"
            />
            <el-table-column
              label="试题数量"
              align="center"
              show-overflow-tooltip
              prop="questionNum"
            />
            <el-table-column
              label="试题总分"
              align="center"
              prop="score"
            >
            </el-table-column>
            <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
            >
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>已选试卷</p>
            <p>（{{ backArr.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div
                v-for="(item, index) in backArr"
                :key="index"
                class="BatchforFlex"
              >
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img
                    :src="require('@/assets/close_.png')"
                    alt=""
                    @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)"
                  />
                </div>
                <span style="padding-left:5px">{{
                  item.paperName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        class="dialog-footer"
        style="margin-top:10px;display:flex;justify-content: center;"
      >
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import treePopupQuestion from "@/components/treePopupQuestion";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
    Empty,
    PageNum,
    // treePopupQuestion,
  },
  mixins: [List],
  data() {
    return {
      backArr: [],
      dialogVisible: false,
      start: false,

      questionBankName: '',
      createTime: '',
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {
  },
  updated() {
  },
  methods: {
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    tinit() {
      this.init();
    },
    change(rowArr) {
      // console.log(rowArr);
      this.backArr = rowArr;
      // this.multipleTable = rowArr;
    },
    async showPopUp(bankId,listData) {
      this.dialogVisible = true;

      this.bankId = bankId;
      this.backArr = listData||[];



      this.start = true;
      if (this.start) {
        await this.getData();

        this.backArr.forEach((e,i)=>{
          this.$refs.multipleTable.toggleRowSelection(e, true);
        })
      }
    },
    async getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        // mockId: this.mockId || "",
        bankId: this.bankId || "",
      };
      if (this.questionBankName) {
        params.paperName = this.questionBankName;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
      if (this.start) {
        return this.doFetch({
          url: "/biz/exam/bank/mock/pagerList",
          params,
          pageNum,
        },true,6);
      }
    },
    deleteImg(itemobj) {
      this.backArr.find((el) => {
        if (el.paperId == itemobj.paperId) {
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1+22 ;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr);
        this.dialogVisible = false;
        this.backArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要配置的试卷",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    },
    doClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    },

  },
};
</script>
<style lang="less">
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
  .Batch {
    padding: 1rem 1rem;
    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .BatchBoxSecond {
        width: 100%;
        .BatchforBox {
          display: flex;
          flex-wrap: wrap;
          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;
            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
